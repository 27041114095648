@media (min-width: @screen-md-min) {
  table.table.table-striped {
    border: 1px solid @table-border-color;
    thead {
      th {
        background-color: @table-head-bg;
        border-bottom: 1px solid @table-head-border-color;
        font-size: 18px;
        font-weight: bold;
        padding: 3px 10px;
        text-align: center;
        vertical-align: middle !important;
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
        }
      }
    }
  }
}

.message-noresult{
  margin-top: -20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-top: none;
}

.table-blu {

  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  th {
    background-color: @table-blu-head-bg !important;
    border-bottom: 1px solid @table-head-border-color;
    color: @table-blu-head-color;
    vertical-align: middle !important;
    &.allegato, &.data {
      width: 2% !important;
      text-align:center;
    }
  }

  thead {
    th {
      font-size: 17px;
      font-weight: bold;
      padding: 3px 10px;
      text-align: center;
      vertical-align: middle !important;
    }
  }

  tbody {
    th {
      width: 25% !important;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;
    display: block;
    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

      thead {
        th {
          font-size: 13px;
          font-weight: bold;
          padding: 3px;
        }
      }
    }
  }
}