.carousel {

  margin-bottom: 30px;

  h1.page-title {
    background-color: @carousel-title-bg;
    color: @carousel-title-color;
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    position: relative;
    text-align: center;
  }
}