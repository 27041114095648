.panel {
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: @panel-margin-bottom-normal;

  @media (max-width: @screen-sm-max) {
    margin-bottom: @panel-margin-bottom-xs;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .panel-heading, .panel-heading-evidenza {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-weight: bold;
    padding: 6px 15px;
    position: relative;

    .panel-title {
      font-size: 15px;
      .titolo-a-sx {
        float: left;
        margin-left: -15px;
        font-weight: bold;
      }
      .icona-a-dx {
        float: right;
        margin-right: -15px;
      }
      img {
        height: 20px;
      }
    }
  }

  .panel-body {
    background-color: @panel-body-bg;
    border: 2px solid @panel-body-border;
    border-top: none;
    padding: 0px;
    
    .panel-container {
      padding: 20px;
    }

    .panel-container-agr {
      padding: 5px;
      padding-top: 0;
    }

    ul.list-unstyled {
      margin-bottom: 0px;
      li {
        background-color: @panel-menu-element-bg;
        padding: 10px 15px;
        -webkit-transition: background 0.15s ease-in;
        -moz-transition: background 0.15s ease-in;
        -ms-transition: background 0.15s ease-in;
        -o-transition: background 0.15s ease-in;
        transition: background 0.15s ease-in;

        &:hover {
          background-color: @panel-menu-element-hover-bg;
          -webkit-transition: background 0.15s ease-in;
          -moz-transition: background 0.15s ease-in;
          -ms-transition: background 0.15s ease-in;
          -o-transition: background 0.15s ease-in;
          transition: background 0.15s ease-in;
          font-weight: bold;
        }

        a {
          color: @panel-menu-element-link-color;
          display: inherit;
        }
      }
    }

    dl.dl-horizontal {
      border-bottom: 1px dashed @panel-list-border-color;
      margin-bottom: 0;
      padding: 20px 0;

      @media (max-width: @screen-sm-max) {
        padding: 15px;
      }

      &:nth-child(even)
      {
        background-color: @panel-list-even-bg;
      }

      &:last-child {
        border-bottom: none;
      }

      dt {
        width: 140px;
      }

      dd {
        margin-bottom: 15px;
        margin-left: 160px;
        @media screen and (max-width: @screen-sm-max) {
          margin-left: 0px;
        }
        padding-right: 20px;
        text-align: justify;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      margin: 10px 0;
    }

    iframe {
      margin: 10px 0;
    }
  }
}

.panel-primary {
  &.panel-news-rif {
    margin-top: 20px;
  }
  &.panel-news-evidenza-rif {
    margin-top: 0px;
  }
  .panel-heading {
    background-color: @panel-primary-heading-bg;
    border-color: @panel-primary-heading-border;
    color: @panel-primary-heading-color;
  }
  .panel-heading-evidenza {
    background-color: @panel-primary-heading-evidenza-bg;
    border-color: @panel-primary-heading-evidenza-border;
    color: @panel-primary-heading-color;
  }
  .panel-title {
    &.panel-news {
      font-size: 18px;
      text-align: center;
    }
  }

  .panel-footer {
    font-weight: bold;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-align: center;

    a {
      color: @panel-default-footer-link-color;
    }
  }
}

.panel-default {
  .panel-heading {
    background-color: @panel-default-heading-bg;
    border-color: @panel-default-heading-border;
    color: @panel-default-heading-color;
    text-align: center;

    .panel-title {
      font-size: 18px;
    }
  }

  .panel-collapse {
    .panel-body{
      padding: 10px;
    }
  }

  .panel-footer {
    background-color: @panel-default-footer-bg;
    font-weight: bold;
    text-align: center;

    a {
      color: @panel-default-footer-link-color;
    }
  }
}
div.input-group {
  @media (max-width: @screen-md-max) {
    padding: 10px;
  }
  span.input-group-btn {
    margin: 5px 0 5px 0;
  }
}

button.custom-blu-btn-link, .custom-blu-btn-link:active {
  background-color: @button-primary-bg;
  i.glyphicon {
    color: @button-primary-color;
  }
}

.banner_img{
  float: none;
  background-color: #003366;
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  display: table;
  height: 60px;
  padding-right: 35%;
  border-left: 1px solid #003366;
}

.banner_img_white{
  float: none;
  background-color: #ffffff;
  background-position: top right;
  background-size: inherit;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  display: table;
  height: 60px;
}

.banner_img a{
  display: table-cell;
  vertical-align: middle;
}

.titolo-banner{
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.1;
  color: #fff;
  font-weight: bold;
  padding: 5px 0;
}

.titolo-banner-white{
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.1;
  color: #003366;
  font-weight: bold;
  padding: 5px 0;
  padding-right: 110px;
}