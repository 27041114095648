.btn {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;

  &.btn-primary {
    color: @button-primary-color;
    background-color: @button-primary-bg;
    border-color: @button-primary-border;
  }
}

.btn-archivio{
  position: absolute;
  top: 5px;
  right: 5px;
}