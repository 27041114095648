.well {
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: @well-bg;
  padding: 10px;
}

.well-banner{
  padding: 4px;
}
