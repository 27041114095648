.pagination {
  li {
    
    a {
      color: @pagination-link-color;
    }
    
    &.active {
      span {
        background-color: @pagination-active-bg;
        border-color: @pagination-active-border;
      }
    }
  }
}